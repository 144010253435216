module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-21705613-10","head":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[],"threshold":0.25},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Unspecified","short_name":"unspecified","start_url":"/","background_color":"#242E3C","theme_color":"#242E3C","display":"minimal-ui","include_favicon":true,"icon":"static/icons/512x512.png","icons":[{"src":"/icons/16x16.png","sizes":"16x16","type":"image/png"},{"src":"/icons/32x32.png","sizes":"32x32","type":"image/png"},{"src":"/icons/96x96.png","sizes":"96x96","type":"image/png"},{"src":"/icons/128x128.png","sizes":"128x128","type":"image/png"},{"src":"/icons/256x256.png","sizes":"256x256","type":"image/png"},{"src":"/icons/512x512.png","sizes":"512x512","type":"image/png"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
